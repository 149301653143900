<template>
  <div>
    <b-row
      v-for="permissao in permissoes"
      :key="permissao.id"
      align-h="between"
      class="mb-1"
    >
      <b-col>
        <span>{{ permissao.label }}</span>
      </b-col>

      <b-col
        v-if="!loading"
        class="text-right"
      >
        <b-form-checkbox
          :checked="checkValidate[modulesSelected.value][permissao.id]"
          class="custom-control-primary"
          name="check-button"
          switch
          @change="setPermissao(permissao, $event)"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'

import { mapState } from 'vuex'

export default {
  components: {
    BRow, BCol, BFormCheckbox,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      modulesSelected: state => state.acl.modulesSelected,
      permissionsSelected: state => state.acl.permissionsSelected,
      checkValidate: state => state.acl.checkValidate,
    }),
    permissoes() {
      const { modulesSelected } = this

      if (!modulesSelected) return []

      const permissionsArrayToCheck = modulesSelected.permissions.map((item, idx) => ({ ...item, id: idx }))

      return permissionsArrayToCheck
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    setPermissao(permissao, status) {
      this.loading = true
      const { modulesSelected } = this

      const permissaoStoreFormat = {
        value: modulesSelected.value,
        key: permissao.key,
        id: permissao.id,
      }
      this.$store.commit('acl/SET_PERMISSIONS', { permission: permissaoStoreFormat, status })

      this.loading = false
    },
  },
}
</script>
