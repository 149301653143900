<template>
  <div>
    <b-card>
      <b-row class="justify-content-between">
        <b-col class="d-flex flex-row">
          <h4>{{ item.name }}</h4>
        </b-col>
        <b-col class="d-flex flex-row-reverse">
          <b-button
            size="md"
            variant="primary"
            class="btn-tour-skip mr-1"
            @click="openModalEditRole(item)"
          >
            Editar
          </b-button>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          ~ usuários
        </b-col>
      </b-row> -->
    </b-card>
  </div>
</template>

<script>
import { BRow, BCard, BCol, BButton } from 'bootstrap-vue'
// {
//   name: 'Administrador',
//   permissions: [
//     { subject: 'manage', actions: ['all'] },
//   ],
// },

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    openModalEditRole: {
      type: Function,
      required: true,
    },
  },
}
</script>
