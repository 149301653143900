<template>
  <validation-observer>
    <b-form v-if="perfilSelected">
      <b-row>
        <b-col md="12">
          <b-form-group>
            <validation-provider
              v-slot="{ errors }"
              name="First Name"
              rules="required"
            >
              <label>Nome</label>
              <b-form-input
                v-model="perfilSelected.name"
                :state="errors.length > 0 ? false : null"
                placeholder="Nome do perfil"
              />
              <small class="text-danger">{{ errors.length > 0 ? 'Campo Obrigatório' : '' }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col md="12">
          <b-form-group>
            <label>Selecione o Módulo</label>
            <v-select
              v-model="modulesSelected"
              :options="avaibleModules"
            />
          </b-form-group>
        </b-col>
      </b-row>

      -->

      <b-row class="">
        <b-col>
          <!-- <span class="mb-1 text-bold">Módulos</span> -->
          <b-tabs>
            <b-tab
              v-for="mod in avaibleModules"
              :key="mod.value"
              @click="selectModule(mod)"
            >
              <template #title>
                <span>{{ mod.label }}</span>
              </template>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <hr>

      <b-row v-show="modulesSelected">
        <b-col>
          <ModuleListItem />
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col class="text-right">
          <b-button
            variant="outline-success"
            @click="savePermission"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BTabs, BTab } from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapState } from 'vuex'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

import ModuleListItem from './ModulesList.vue'

export default {
  components: {
    BRow,
    BCol,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    ModuleListItem,
    BTabs,
    BTab,
    BButton,
  },
  props: {
    savePermission: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    ...mapState({
      perfilSelected: state => state.acl.perfilSelected,
      avaibleModules: state => state.acl.avaibleModules,
    }),
    modulesSelected: {
      get() {
        return this.$store.state.acl.modulesSelected
      },
      set(value) {
        this.$store.commit('acl/SET_MODULE', value)
      },
    },
  },
  mounted() {
    this.$store.commit('acl/SET_MODULE', this.avaibleModules[0])
  },
  methods: {
    selectModule(item) {
      this.$store.commit('acl/SET_MODULE', item)
    },
  },
}
</script>
