<template>
  <div>
    <b-button
      size="md"
      variant="outline-primary"
      class="btn-tour-skip mb-1"
      @click="createPefil"
    >
      Novo perfil
    </b-button>
    <b-row class="match-height">
      <b-col
        v-for="item in roles"
        :key="item.id"
        md="3"
      >
        <RoleCard
          :item="item"
          :open-modal-edit-role="openModalEditRole"
        />
      </b-col>
    </b-row>

    <b-modal
      ref="editRole"
      :show="perfilSelected"
      size="lg"
      hide-footer
      title="Editar perfil"
      no-close-on-backdrop
      @hide="$store.dispatch('acl/clearEditModal')"
      @ok="savePermission"
    >
      <RoleModal :save-permission="savePermission" />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import axios from '@/../axios-auth'

import { mapState } from 'vuex'

import RoleCard from './components/RoleCard.vue'
import RoleModal from './components/RoleModal.vue'
// import CreateRole from './components/CreateRole.vue'

export default {
  components: {
    BRow,
    BCol,
    RoleCard,
    BButton,
    RoleModal,
  },
  data() {
    return {
      roles: [
        // {
        //   name: 'Administrador',
        //   permissions: [
        //     { subject: 'manage', actions: ['all'] },
        //   ],
        // },
      ],
    }
  },
  computed: {
    ...mapState({
      perfilSelected: state => state.acl.perfilSelected,
    }),
  },
  created() {
    this.getRoles()
  },
  methods: {
    getRoles() {
      const userData = JSON.parse(localStorage.userData)

      const empresaId = userData.empresa_id

      axios.get(`api/perfil/${empresaId}`).then(res => {
        this.roles = []
        const { dados } = res.data

        dados.forEach(perfilEmpresa => {
          const perfil = {
            perfilId: perfilEmpresa.id,
            name: perfilEmpresa.perfil.nome,
            permissoes: perfilEmpresa.permissoes,
          }
          this.roles = [...this.roles, perfil]
        })
      })
    },

    openModalEditRole(item) {
      const itemWithoutObserver = JSON.stringify(item)
      this.$store.commit('acl/SET_PERFIL_SELECTED', JSON.parse(itemWithoutObserver))
      this.$refs.editRole.show()
    },

    createPefil() {
      const newPerfil = JSON.stringify({
        name: '',
        perfilId: null,
        permissoes: '{}',
      })

      this.$store.commit('acl/SET_PERFIL_SELECTED', JSON.parse(newPerfil))
      this.$refs.editRole.show()
    },

    async savePermission() {
      await this.$store.dispatch('acl/saveAcl')
      this.getRoles()
      this.$refs.editRole.hide()
    },
  },
}
</script>
